import type { AlpineComponent } from 'alpinejs'

type Status = string

type Props = {
    value: Status
}

type StatusUpdatedEvent = CustomEvent<{
    status: Status
}>

type ChangeEntryStatus = AlpineComponent<{
    isEditing: boolean
    value: Status
    cancel(): void
    handleStatusUpdated(event: StatusUpdatedEvent): void
}>

const Component = (props: Props): ChangeEntryStatus => ({
    isEditing: false,

    value: props.value,

    cancel(): void {
        this.isEditing = false
    },

    handleStatusUpdated(event: StatusUpdatedEvent): void {
        this.isEditing = false
        this.value = event.detail.status
    },
})

export default Component
