import { DocumentType } from '../../global'

type DocumentUploadDoneEvent = CustomEvent<{
    type: DocumentType
    name: string
}>

export default () => ({
    droppingQuotes: false,
    droppingAttachments: false,

    quotes: [] as File[],
    attachments: [] as File[],

    uploadQuotes(files: FileList): void {
        this.quotes.push(...files)

        for (const file of files) {
            // @ts-ignore
            this.$wire.upload('quote', file)
        }
    },

    uploadAttachments(files: FileList): void {
        this.attachments.push(...files)

        for (const file of files) {
            // @ts-ignore
            this.$wire.upload('attachment', file)
        }
    },

    handleDocumentUploadDone(event: DocumentUploadDoneEvent): void {
        if (event.detail.type === 'quote') {
            this.quotes = this.quotes.filter(({ name }) => name !== event.detail.name)
        } else {
            this.attachments = this.attachments.filter(({ name }) => name !== event.detail.name)
        }
    },
})
